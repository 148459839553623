/* eslint-disable @next/next/no-img-element */
import { useContext } from "react";
import Link from "next/link";
import { GlobeAltIcon } from "@heroicons/react/solid";
import AppContext from "context/app";
import styles from "./card.module.css";

export default function Card({ ...props }) {
  const { link, image, title, type, additional, location } = props;
  const { user } = useContext(AppContext);
  // hack
  const isRestroom = title.toLowerCase().includes("restroom");
  return (
    <Link href={link}>
      <a className="group relative block">
        {user?.CanAccessAdmin && location?.Latitude && location?.Longitude && (
          <div
            className="absolute top-0 right-0 z-10 rounded-tr rounded-bl bg-blue-500 p-2"
            aria-hidden="true"
          >
            <GlobeAltIcon className="h-4 w-4 fill-white" />
          </div>
        )}
        <div
          aria-hidden="true"
          className="aspect-w-16 aspect-h-9 overflow-hidden rounded-lg group-hover:opacity-75"
        >
          {image ? (
            <img
              src={`https://images.opencoaster.com/800x400/${image.hash}${image.ext}`}
              srcSet={`
                https://images.opencoaster.com/800x400/${image.hash}${image.ext} 800w,
                https://images.opencoaster.com/600x300/${image.hash}${image.ext} 600w,
                https://images.opencoaster.com/400x200/${image.hash}${image.ext} 400w,
                https://images.opencoaster.com/300x150/${image.hash}${image.ext} 300w,
                https://images.opencoaster.com/200x100/${image.hash}${image.ext} 200w,
                https://images.opencoaster.com/100x50/${image.hash}${image.ext} 100w
              `}
              alt={image.alternativeText ? image.alternativeText : title}
              className="h-full w-full object-cover object-center"
            />
          ) : (
            <div className="h-full w-full bg-gray-200 object-cover object-center">
              <div className={`flex justify-center ${styles.contents}`}>
                <img
                  className="flex"
                  src={`/placeholder-${isRestroom ? "restroom" : type}.svg`}
                  alt={title}
                />
              </div>
            </div>
          )}
        </div>
        {additional && (
          <div className="mt-3 text-sm text-gray-500">{additional}</div>
        )}
        <h3
          className={`text-base font-semibold text-gray-900 ${
            !additional ? "mt-3" : ""
          }`}
        >
          {title}
        </h3>
      </a>
    </Link>
  );
}
