import { gql } from "graphql-request";
import MainPhoto from "queries/fragments/MainPhoto";

const ParkCard = gql`
  ${MainPhoto}

  fragment ParkCard on Parks {
    id
    Name
    Slug
    CurrentlyOperating
    IsTempClosed
    MainPhoto {
      ...MainPhoto
    }
    Location {
      Latitude
      Longitude
    }
  }
`;

export default ParkCard;
