import { SWRConfig } from "swr";
import query from "utils/query";

export default function Config({ children, fallback }) {
  return (
    <SWRConfig
      value={{
        refreshInterval: 1000 * 60 * 2,
        fetcher: (q, variables) => query(q, variables),
        fallback,
      }}
    >
      {children}
    </SWRConfig>
  );
}
