import { useContext } from "react";
import CountUp from "react-countup";
import Link from "next/link";
import useSWR from "swr";

import { formatNumber } from "utils/intl";
import AppContext from "context/app";
import AttractionsIcon from "public/placeholder-attraction.svg";
import ParksIcon from "public/placeholder-park.svg";
import ResortsIcon from "public/placeholder-resort.svg";
import CompaniesIcon from "public/placeholder-company.svg";
import ManufacturersIcon from "public/placeholder-manufacturer.svg";

import SITE_STATS_QUERY from "queries/siteStats";

export default function SiteStats() {
  const { user } = useContext(AppContext);
  const { data, error } = useSWR(SITE_STATS_QUERY);

  if (error) {
    return null;
  }

  return (
    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
        <dt>
          <div className="absolute rounded-md bg-indigo-500 p-3">
            <AttractionsIcon className="h-6 w-6 fill-white" />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Attractions
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
          <p className="text-2xl font-semibold text-gray-900">
            {data?.attractionsCount}
            {/* <CountUp
              end={data?.attractionsCount}
              formattingFn={formatNumber}
              start={data?.attractionsCount * 0.98}
              duration={5}
              useEasing
            /> */}
          </p>
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <Link href="/attractions">
                <a className="font-medium text-indigo-600 hover:text-indigo-500">
                  View all
                  <span className="sr-only"> attractions</span>
                </a>
              </Link>
            </div>
          </div>
        </dd>
      </div>
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
        <dt>
          <div className="absolute rounded-md bg-indigo-500 p-3">
            <ParksIcon className="h-6 w-6 fill-white" />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Parks
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
          <p className="text-2xl font-semibold text-gray-900">
            {data?.parksCount}
            {/* <CountUp
              end={data?.parksCount}
              formattingFn={formatNumber}
              start={data?.parksCount * 0.7}
              duration={5}
              useEasing
            /> */}
          </p>
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <Link href="/parks">
                <a className="font-medium text-indigo-600 hover:text-indigo-500">
                  View all
                  <span className="sr-only"> parks</span>
                </a>
              </Link>
            </div>
          </div>
        </dd>
      </div>
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
        <dt>
          <div className="absolute rounded-md bg-indigo-500 p-3">
            <ResortsIcon className="h-6 w-6 fill-white" />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Resorts
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
          <p className="text-2xl font-semibold text-gray-900">
            {data?.resortsCount}
            {/* <CountUp
              end={data?.resortsCount}
              formattingFn={formatNumber}
              start={data?.resortsCount * 0.3}
              duration={5}
              useEasing
            /> */}
          </p>
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <Link href="/resorts">
                <a className="font-medium text-indigo-600 hover:text-indigo-500">
                  View all
                  <span className="sr-only"> resorts</span>
                </a>
              </Link>
            </div>
          </div>
        </dd>
      </div>
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
        <dt>
          <div className="absolute rounded-md bg-indigo-500 p-3">
            <CompaniesIcon className="h-6 w-6 fill-white" />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Companies
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
          <p className="text-2xl font-semibold text-gray-900">
            {data?.companiesCount}
            {/* <CountUp
              end={data?.companiesCount}
              formattingFn={formatNumber}
              start={data?.companiesCount * 0.1}
              duration={5}
              useEasing
            /> */}
          </p>
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <Link href="/companies">
                <a className="font-medium text-indigo-600 hover:text-indigo-500">
                  View all
                  <span className="sr-only"> companies</span>
                </a>
              </Link>
            </div>
          </div>
        </dd>
      </div>
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
        <dt>
          <div className="absolute rounded-md bg-indigo-500 p-3">
            <ManufacturersIcon className="h-6 w-6 fill-white" />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Manufacturers
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
          <p className="text-2xl font-semibold text-gray-900">
            {data?.manufacturersCount}
            {/* <CountUp
              end={data?.manufacturersCount}
              formattingFn={formatNumber}
              start={data?.manufacturersCount * 0.3}
              duration={5}
              useEasing
            /> */}
          </p>
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              <Link href="/manufacturers">
                <a className="font-medium text-indigo-600 hover:text-indigo-500">
                  View all
                  <span className="sr-only"> manufacturers</span>
                </a>
              </Link>
            </div>
          </div>
        </dd>
      </div>
      <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6">
        <dt>
          <div className="absolute rounded-md bg-indigo-500 p-3">
            <AttractionsIcon className="h-6 w-6 fill-white" />
          </div>
          <p className="ml-16 truncate text-sm font-medium text-gray-500">
            Ride Credits
          </p>
        </dt>
        <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
          <p className="text-2xl font-semibold text-gray-900">
            {data?.rideCreditCount}
            {/* <CountUp
              end={data?.rideCreditCount}
              formattingFn={formatNumber}
              start={data?.rideCreditCount * 0.9}
              duration={5}
              useEasing
            /> */}
          </p>
          <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
            <div className="text-sm">
              {user ? (
                <Link href={`/profile/${user.id}`}>
                  <a className="font-medium text-indigo-600 hover:text-indigo-500">
                    View yours
                  </a>
                </Link>
              ) : (
                <Link href="/login">
                  <a className="font-medium text-indigo-600 hover:text-indigo-500">
                    Login to track
                  </a>
                </Link>
              )}
            </div>
          </div>
        </dd>
      </div>
    </dl>
  );
}
