import { GraphQLClient } from "graphql-request";
import { GRAPH_URL } from "utils/const";

export default function query(query, args) {
  let variables = args;
  let token;

  if (args?.variables) {
    variables = args.variables;
  }

  if (args?.token && typeof args?.token === "string") {
    token = args.token;
  }

  const client = new GraphQLClient(GRAPH_URL);

  if (token) {
    client.setHeader("authorization", `Bearer ${token}`);
  }

  return client.request(query, variables);
}
