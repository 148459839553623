import { gql } from "graphql-request";

const SITE_STATS_QUERY = gql`
  query SiteStats {
    attractionsCount
    companiesCount
    manufacturersCount
    parksCount
    resortsCount
    rideCreditCount
  }
`;

export default SITE_STATS_QUERY;
