import { useContext } from "react";
import AppContext from "context/app";
import Card from "components/global/card";

export default function RecentlyRecommended() {
  const {
    recommendedRecentPark,
    recommendedRecentAttraction,
    recommendedRecentResort,
  } = useContext(AppContext);

  if (
    !recommendedRecentPark &&
    !recommendedRecentAttraction &&
    !recommendedRecentResort
  ) {
    return null;
  }

  return (
    <>
      <h3 className="mb-8 font-serif text-3xl text-gray-800">Recommended</h3>
      <div className="mb-12">
        <ul className="mb-10 grid grid-cols-2 gap-8 md:grid-cols-3">
          {recommendedRecentAttraction && (
            <li>
              <Card
                link={`/park/${recommendedRecentAttraction.Park.Slug}/${recommendedRecentAttraction.Slug}`}
                title={recommendedRecentAttraction.Name}
                image={recommendedRecentAttraction.MainPhoto}
                type="attraction"
                additional={recommendedRecentAttraction.Park.Name}
              />
            </li>
          )}
          {recommendedRecentPark && (
            <li>
              <Card
                link={`/park/${recommendedRecentPark.Slug}`}
                title={recommendedRecentPark.Name}
                image={recommendedRecentPark.MainPhoto}
                type="park"
                isTempClosed={recommendedRecentPark.IsTempClosed}
              />
            </li>
          )}
          {recommendedRecentResort && (
            <li>
              <Card
                link={`/resort/${recommendedRecentResort.Slug}`}
                title={recommendedRecentResort.Name}
                image={recommendedRecentResort.MainPhoto}
                type="resort"
              />
            </li>
          )}
        </ul>
      </div>
    </>
  );
}
