import { useContext } from "react";
import Router from "next/router";
import AppContext from "context/app";

const Registered = () => {
  const appContext = useContext(AppContext);
  const unsetMessage = () => {
    if (appContext.flashMessage === "registered") {
      appContext.setFlashMessage(null);
    }

    Router.events.off("routeChangeComplete", unsetMessage);
  };

  Router.events.on("routeChangeComplete", unsetMessage);

  return (
    <>
      <div className="m-6 bg-emerald-400 text-gray-900 p-6 container mx-auto">
        <p>
          Welcome! You can now start tracking your ride credits. Use the search
          below to get going!
        </p>
      </div>
    </>
  );
};

export default Registered;
