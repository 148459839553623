import { useContext } from "react";
import Head from "next/head";
import query from "utils/query";
import Registered from "components/messages/registered";
import SiteStats from "components/site/stats";
import AppContext from "context/app";
import RecentAttractions from "components/attractions/recent";
import RecentParks from "components/parks/recent";
import Config from "components/config";
import RecentlyRecommended from "components/recommended/recent";
import NearbyRecommended from "components/recommended/nearby";

// These are for static props
import SITE_STATS_QUERY from "queries/siteStats";
import RECENT_PARKS_QUERY from "queries/recent-parks";
import RECENT_ATTRACTION_QUERY from "queries/recent-attractions";

export default function Home({ fallback }) {
  const appContext = useContext(AppContext);

  return (
    <Config fallback={fallback}>
      <Head>
        <title>Open Coaster</title>
      </Head>
      {appContext.flashMessage && appContext.flashMessage === "registered" && (
        <Registered />
      )}
      <main className="container m-6 mx-auto px-6 sm:px-0">
        <NearbyRecommended />
        <RecentlyRecommended />
        <div className="mb-8">
          <SiteStats />
        </div>
        <h3 className="mb-8 font-serif text-3xl text-gray-800">
          Recently Updated Parks
        </h3>
        <RecentParks />
        <h3 className="mb-8 font-serif text-3xl text-gray-800">
          Recently Updated Attractions
        </h3>
        <RecentAttractions />
      </main>
    </Config>
  );
}

export async function getStaticProps() {
  const fallback = {};

  const data = await Promise.all([
    query(SITE_STATS_QUERY),
    query(RECENT_PARKS_QUERY),
    query(RECENT_ATTRACTION_QUERY),
  ]);

  fallback[SITE_STATS_QUERY] = data[0];
  fallback[RECENT_PARKS_QUERY] = data[1];
  fallback[RECENT_ATTRACTION_QUERY] = data[2];

  return {
    props: {
      fallback,
    },
  };
}
