import useSWR from "swr";
import Link from "next/link";
import Card from "components/global/card";
import RECENT_ATTRACTION_QUERY from "queries/recent-attractions";

export default function RecentAttractions() {
  const { data, error } = useSWR(RECENT_ATTRACTION_QUERY);

  if (error) {
    console.error(error);
    return null;
  }

  if (!Array.isArray(data?.attractions)) {
    return null;
  }

  return (
    <div className="mb-12">
      <ul className="mb-10 grid grid-cols-2 gap-8 md:grid-cols-3 lg:grid-cols-4">
        {data?.attractions?.map((attraction) => {
          if (!attraction.Park) {
            console.warn("MISSING PARK", attraction);
            return null;
          }
          return (
            <li key={`recent-attraction-${attraction.id}`}>
              <Card
                link={`/park/${attraction.Park.Slug}/${attraction.Slug}`}
                title={attraction.Name}
                image={attraction.MainPhoto}
                type="attraction"
                additional={attraction.Park.Name}
              />
            </li>
          );
        })}
      </ul>
      <div className="mt-6 flex justify-end text-right text-lg text-gray-500">
        <p>
          <Link href="/attractions">
            <a className="font-medium text-indigo-600 hover:text-indigo-500">
              All attractions<span aria-hidden="true"> &rarr;</span>
            </a>
          </Link>
        </p>
      </div>
    </div>
  );
}
