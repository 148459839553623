import { gql } from "graphql-request";

const MainPhoto = gql`
  fragment MainPhoto on UploadFile {
    caption
    alternativeText
    hash
    ext
    width
    height
  }
`;

export default MainPhoto;
