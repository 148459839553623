import { useEffect, useState } from "react";

export default function useGeolocationWatch(consentGiven, options) {
  const [position, setPosition] = useState(null);
  const [error, setError] = useState(null);
  const [watch, setWatch] = useState(null);

  useEffect(() => {
    if (consentGiven && !watch) {
      setWatch(
        navigator.geolocation.watchPosition(setPosition, setError, options)
      );
    }

    return () => navigator.geolocation.clearWatch(watch);
  }, [consentGiven, options, watch]);

  return [position, error];
}
