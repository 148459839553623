import useSWR from "swr";
import Link from "next/link";
import Card from "components/global/card";
import RECENT_PARKS_QUERY from "queries/recent-parks";

export default function RecentParks() {
  const { data, error } = useSWR(RECENT_PARKS_QUERY);

  if (error) {
    console.error(error);
  }

  if (!Array.isArray(data?.parks)) {
    return null;
  }

  return (
    <div className="mb-12">
      <ul className="mb-10 grid grid-cols-2 gap-8 md:grid-cols-3">
        {data?.parks?.map((park) => (
          <li key={`recent-park-${park.id}`}>
            <Card
              link={`/park/${park.Slug}`}
              title={park.Name}
              image={park.MainPhoto}
              type="park"
              isTempClosed={park.IsTempClosed}
            />
          </li>
        ))}
      </ul>
      <div className="mt-6 flex justify-end text-right text-lg text-gray-500">
        <p>
          <Link href="/parks">
            <a className="font-medium text-indigo-600 hover:text-indigo-500">
              All parks<span aria-hidden="true"> &rarr;</span>
            </a>
          </Link>
        </p>
      </div>
    </div>
  );
}
