import { gql } from "graphql-request";

const ParkLite = gql`
  fragment ParkLite on Parks {
    id
    Name
    Slug
    MainPhoto {
      ...MainPhoto
    }
    Resort {
      id
      Name
      Slug
    }
  }
`;

export default ParkLite;
