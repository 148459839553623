import useSWR from "swr";
import useGeolocationWatch from "hooks/useGeolocationWatch";
import useToggle from "hooks/useToggle";
import fetcher from "utils/fetcher";
import Card from "components/global/card";
import { SEARCH_TOKEN, SEARCH_URL } from "utils/const";
import { MetersFromMiles } from "utils/units";

export default function RecommendedNearby() {
  const [consentGiven, toggleConsentGiven] = useToggle(false);
  const [position, positionError] = useGeolocationWatch(consentGiven, {
    maximumAge: 30 * 1000, // 30s
    timeout: 20 * 1000, // 10s
    enableHighAccuracy: false,
  });

  const { data: fallbackGeo } = useSWR("/api/geo", fetcher);
  const { data: searchResults } = useSWR(() => {
    if (!consentGiven) return false;

    let geo;

    if (
      (!position?.coords?.latitude || !position?.coords?.longitude) &&
      (!fallbackGeo?.latitude || !fallbackGeo?.longitude)
    ) {
      console.log("No location found");
      return false;
    }

    if (position?.coords?.latitude && position?.coords?.longitude) {
      geo = {
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      };
    } else if (fallbackGeo?.latitude && fallbackGeo?.longitude) {
      geo = {
        latitude: fallbackGeo.latitude,
        longitude: fallbackGeo.longitude,
      };
    }

    if (!geo) {
      return false;
    }

    return {
      method: "POST",
      url: `${SEARCH_URL}/indexes/omnisearch/search`,
      data: {
        filter: `_geoRadius(${geo.latitude},${geo.longitude}, ${MetersFromMiles(
          100
        )})`,
        sort: [`_geoPoint(${geo.latitude},${geo.longitude}):asc`],
        limit: 3,
      },
      headers: {
        Authorization: `Bearer ${SEARCH_TOKEN}`,
      },
    };
  }, fetcher);

  return (
    <>
      <h3 className="mb-8 font-serif text-3xl text-gray-800">Nearby</h3>
      <div className="mb-12">
        {!consentGiven && (
          <>
            <button
              type="button"
              className="rounded bg-blue-500 py-2 px-4 font-bold text-white hover:bg-blue-700 focus:outline-none focus:ring"
              onClick={toggleConsentGiven}
            >
              Show What&apos;s Nearby
            </button>
            <p>
              We only use your location to show nearby results and is not stored
              or linked to your account.
            </p>
          </>
        )}
        {positionError &&
          positionError.POSITION_UNAVAILABLE !== positionError.code && (
            <p>
              To enable more accurate location you need to click the pages info
              icon and enable location. This may vary based on your browser.
            </p>
          )}
        {searchResults && searchResults?.hits?.length > 0 && (
          <ul className="mb-10 grid grid-cols-2 gap-8 md:grid-cols-3">
            {searchResults?.hits.map((hit) => {
              let link = `/${hit.contentType}/${hit.slug}`;

              let additional = "";

              // Make attraction urls work
              if (hit.contentType === "attraction") {
                if (!hit.parentType || !hit.parentSlug) {
                  return null;
                }

                link = `/${hit.parentType}/${hit.parentSlug}/${hit.slug}`;

                if (hit.parentName) {
                  additional = hit.parentName;
                }
              }

              return (
                <li key={`nearby-${hit.id}`}>
                  <Card
                    link={link}
                    title={hit.title}
                    image={hit.photo}
                    type={hit.contentType}
                    additional={additional}
                    isTempClosed={hit.isTempClosed}
                  />
                </li>
              );
            })}
          </ul>
        )}
        {searchResults?.hits?.length === 0 && (
          <p>We couldn&apos;t find anything within 100 miles.</p>
        )}
      </div>
    </>
  );
}
