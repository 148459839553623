import { gql } from "graphql-request";
import ParkCard from "queries/fragments/ParkCard";

const RECENT_PARKS_QUERY = gql`
  ${ParkCard}

  query Parks {
    parks(sort: "updated_at:desc", limit: 6) {
      ...ParkCard
    }
  }
`;

export default RECENT_PARKS_QUERY;
