import { gql } from "graphql-request";
import MainPhotoFragment from "queries/fragments/MainPhoto";
import ParkLite from "queries/fragments/ParkLite";

const RECENT_ATTRACTION_QUERY = gql`
  ${MainPhotoFragment}
  ${ParkLite}

  query Attractions {
    attractions(sort: "updated_at:desc", limit: 12) {
      Name
      Slug
      id
      Park {
        ...ParkLite
      }
      CoverPhoto {
        ...MainPhoto
      }
      MainPhoto {
        ...MainPhoto
      }
    }
  }
`;

export default RECENT_ATTRACTION_QUERY;
